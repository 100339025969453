import { gql } from '~/__gql-generated__';
import groupBy from 'lodash.groupby';
import { cache, createAsync } from '@solidjs/router';
import { For, Show, Suspense } from 'solid-js';

import { PartnerType } from '~/__gql-generated__/graphql';
import styles from '~/components/PartnerList.module.scss';
import rootStyles from '~/components/Root.module.scss';
import { client } from '~/utils/graphql';

const labels = {
  [PartnerType.Top]: 'Top Parceiros',
  [PartnerType.Live]: 'Top Parceiros Live',
  [PartnerType.Normal]: 'Mais Parceiros',
};

const PARTNERS = gql(`
  query Partners {
    partners(options: { sort: [{ title: ASC }] }) {
      type
      title
      link
      picture {
        url
      }
    }
  }
`);

const getPartners = cache(async () => {
  'use server';

  const { data } = await client.query({ query: PARTNERS });

  return groupBy(data.partners, partner => partner.type);
}, 'partners');

export default function PartnerList() {
  const data = createAsync(() => getPartners());

  return (
    <Suspense>
      <section class={styles.wrapper}>
        <h2 class={rootStyles['sr-only']}>Parceiros</h2>
        <For each={Object.keys(labels)} fallback="Ninguém...">
          {key => (
            <Show when={data()?.[key]?.length}>
              <h3>{labels[key as keyof typeof labels]}</h3>
              <ul>
                <For each={data()?.[key]}>
                  {partner => (
                    <li>
                      <a rel="external" href={partner.link}>
                        <img
                          loading="lazy"
                          width={88}
                          height={31}
                          alt={partner.title}
                          src={partner.picture.url}
                        />
                      </a>
                    </li>
                  )}
                </For>
              </ul>
            </Show>
          )}
        </For>
      </section>
    </Suspense>
  );
}
