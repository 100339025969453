import { MetaProvider } from '@solidjs/meta';
import { A, type RouteSectionProps } from '@solidjs/router';
import { clientOnly } from '@solidjs/start';
import 'pollen-css/pollen.css';
import { SolidNProgress } from 'solid-progressbar';
import { Suspense } from 'solid-js';

import '~/code-highlight.scss';
import ColorSchemeSwitcher from '~/components/ColorSchemeSwitcher';
import Header from '~/components/Header';
import PartnerList from '~/components/PartnerList';
import PlayerContext from '~/components/PlayerContext';
import styles from '~/components/Root.module.scss';

const Player = clientOnly(() => import('~/components/player'));
const SongLyricsModal = clientOnly(
  () => import('~/components/player/SongLyricsModal'),
);
const SongRequestModal = clientOnly(
  () => import('~/components/SongRequestModal'),
);

export default function Root(props: RouteSectionProps) {
  return (
    <MetaProvider>
      <PlayerContext>
        <SolidNProgress
          color="light-dark(var(--color-sky-400), var(--color-sky-600))"
          stopDelayMs={0}
          options={{ showSpinner: false }}
        />
        <Header />
        <Suspense>{props.children}</Suspense>
        <div class={styles.footer}>
          <div class={styles.container}>
            <PartnerList />
            <hr />
            <ColorSchemeSwitcher />
            <p
              classList={{
                [styles.bylines]: true,
                [styles['styled-links']]: true,
              }}
            >
              Rádio J-Hero v5.0 — Do seu jeito, do seu gosto!
              <br />
              Articles and logo are © 2008–{new Date().getFullYear()} Rádio
              J-Hero. All rights reserved.
              <br />
              All dates are displayed according to{' '}
              <A rel="external" href="https://time.is/Brasília">
                Brasília Time
              </A>
              .<br />
              Design by{' '}
              <A rel="external" href="/kuro">
                Elison
              </A>
              . Icons by{' '}
              <A rel="external" href="https://tabler.io/icons">
                Tabler
              </A>
              . Powered by{' '}
              <A rel="external" href="https://netlify.com/">
                Netlify
              </A>
              .
            </p>
          </div>
        </div>
        <Player />
        <SongLyricsModal />
        <SongRequestModal />
      </PlayerContext>
    </MetaProvider>
  );
}
