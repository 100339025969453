import Tooltip from '@corvu/tooltip';
import { A } from '@solidjs/router';
import styles from '~/components/Header.module.scss';
import rootStyles from '~/components/Root.module.scss';
import { generateRetinaThumbs } from '~/utils/generateThumbs';

export default function Header() {
  return (
    <div class={styles.wrapper}>
      <div classList={{ [rootStyles.container]: true, [styles.inner]: true }}>
        <h1 class={styles.logo}>
          <A href="/">
            <img
              src="/assets/logo.png"
              srcset={generateRetinaThumbs(
                `${import.meta.env.VITE_SITE_HOST}/assets/logo.png`,
                'x48',
              )}
              alt="Rádio J-Hero"
              height={48}
            />
          </A>
        </h1>
        <nav>
          <A href="/grade">Grade</A>
          <A href="/colunas">Colunas</A>
          <A href="/chat">Discord</A>
          <A href="/aplicativo">App</A>
          <A href="/contato">Contato</A>
          <A href="/quem-somos">Sobre</A>
        </nav>
        <Tooltip
          placement="bottom"
          strategy="fixed"
          openDelay={200}
          hoverableContent={false}
          floatingOptions={{
            offset: 10,
            flip: true,
            shift: true,
          }}
        >
          <Tooltip.Trigger
            as="a"
            class={styles.search}
            href="#site-search"
            aria-label="Busca"
          >
            <IconTablerSearch />
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content>
              Busca
              <Tooltip.Arrow size={10} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip>
      </div>
    </div>
  );
}
